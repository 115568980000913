<!--
 * @Author: Dyf
 * @Date: 2023-11-07 11:33:40
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-11-20 17:03:08
 * @Description: 严选课程
-->
<style lang="scss" scoped>
.strict-courses {
  @include innerPage($p: 24px 0 0);
  @include pageTitle(0);
  @include pageFoot;
  .course-container {
    height: calc(100% - 88px);
    .course-inner {
      width: 100%;
      height: calc(100% - 86px);
      position: relative;
      margin-top: 32px;
      .list-inner {
        width: calc(100% + 20px);
        height: 100%;
        overflow: hidden;
        overflow-y: auto;
        box-sizing: border-box;
        padding-right: 20px;
        flex-wrap: wrap;
        @include flexBox(space-between, flex-start);

        &::after {
          content: "";
          width: calc((100% - 98px) / 3);
        }

        .item {
          width: calc((100% - 98px) / 3);
          height: 286px;
          border-radius: 10px;
          background: #fff;
          margin-bottom: 36px;
          overflow: hidden;

          .title {
            height: 60px;
            box-sizing: border-box;
            padding-left: 56px;
            padding-right: 20px;
            background: #f6f7fc;
            position: relative;
            @include flexBox;

            &::before {
              content: "\e62f";
              font-family: "iconfont";
              font-size: 28px;
              color: rgba($color: #44269a, $alpha: 0.21);
              line-height: 64px;
              position: absolute;
              left: 24px;
              top: 0;
            }

            h4 {
              flex-grow: 1;
              margin-right: 20px;
            }

            .el-tag {
              flex-shrink: 0;
              margin-right: 10px;

              &:last-child {
                margin-right: 0;
              }
            }
          }

          .about-info {
            height: calc(100% - 146px);
            box-sizing: border-box;
            padding-left: 60px;
            padding-right: 18px;
            flex-direction: column;
            @include flexBox(center, flex-start);

            li {
              position: relative;
              box-sizing: border-box;
              padding-left: 14px;
              line-height: 30px;
              color: #595959;

              &::before {
                content: "";
                width: 8px;
                height: 8px;
                background: #e8eaf3;
                border-radius: 50%;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
              }
            }
          }

          .foot {
            width: calc(100% - 36px);
            height: 86px;
            margin: 0 auto;
            box-sizing: border-box;
            border-top: 1px solid rgba($color: #e1dced, $alpha: 0.8);
            padding-left: 16px;
            @include flexBox(space-between);

            .view-num,
            .download-num {
              color: #7a7a7a;
              line-height: 24px;
              padding-right: 20px;
              flex-shrink: 0;
              margin-right: 20px;

              .pf_bold {
                font-size: 22px;
                color: #44269a;
              }
            }
            .view-num {
              border-right: 1px solid rgba($color: #7a7a7a, $alpha: 0.11);
              margin-left: auto;
            }
            .el-button {
              //   margin-left: auto;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .strict-courses {
    @include innerPage($p: 18px 0 0);
    .course-container {
      height: calc(100% - 68px);
      .course-inner {
        height: calc(100% - 44px);
        margin-top: 22px;
        .list-inner {
          width: calc(100% + 10px);
          padding-right: 10px;
          .item {
            width: calc((100% - 32px) / 3);
            height: 236px;
            margin-bottom: 18px;
            .title {
              height: 54px;
              padding-left: 38px;
              padding-right: 10px;
              h4 {
                font-size: 16px;
                line-height: 54px;
                margin-right: 10px;
              }
              &::before {
                line-height: 56px;
                font-size: 24px;
                left: 8px;
              }
              .el-tag {
                height: 24px;
                padding: 0 8px;
                border-radius: 6px;
                line-height: 24px;
                font-size: 12px;
              }
            }
            .about-info {
              height: calc(100% - 114px);
              padding-left: 44px;
              padding-right: 10px;
            }
            .foot {
              width: calc(100% - 20px);
              padding-top: 8px;
              height: 60px;
              .el-button {
                width: 74px;
                height: 32px;
                font-size: 12px;
              }
              .view-num {
                font-size: 14px;
                padding-right: 14px;
                margin-right: 14px;
                .pf_bold {
                  font-size: 18px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
<template>
  <section class="strict-courses">
    <div class="page-title">
      <h3>严选课程</h3>
      <div class="search-form">
        <el-form inline :model="searchForm">
          <el-form-item>
            <el-select
              v-model="searchForm.plstr_cur_grade"
              placeholder="选择年级"
            >
              <el-option
                v-for="item in gradeList"
                :key="item.grade_id"
                :label="item.grade_name"
                :value="item.grade_id"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select v-model="searchForm.sysub_id" placeholder="选择科目">
              <el-option
                v-for="item in subjectList"
                :key="item.sysub_id"
                :label="item.sysub_name"
                :value="item.sysub_id"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="searchForm.plstr_cur_title"
              maxlength="100"
              placeholder="搜索课程名称"
              clearable
            />
          </el-form-item>
        </el-form>
        <el-button type="custom_primary" size="medium" @click="searchData"
          >搜索</el-button
        >
      </div>
    </div>
    <div class="course-container">
      <div
        class="course-inner"
        :class="{ 'no-data': $isEmpty(listData) }"
        v-loading="dataLoad"
      >
        <ul class="list-inner" v-if="!$isEmpty(listData)">
          <li class="item" v-for="item in listData" :key="item.plstr_cur_id">
            <div class="title">
              <h4 class="bold line-text--1st">
                {{ item.plstr_cur_title || "-" }}
              </h4>
              <el-tag v-if="item.sysub_name">{{ item.sysub_name || "-" }}</el-tag>
            </div>
            <ul class="about-info">
              <li>章节：{{ item.plstr_cur_lable }}</li>
              <li>教师：{{ item.teuse_name }}</li>
              <li>时间：{{ item.plstr_cur_publishtime }}</li>
            </ul>
            <div class="foot">
              <p class="view-num">
                <span class="pf_bold">{{ item.plstr_cur_read }}</span>
                <span>人看过</span>
              </p>
              <!-- <p class="download-num">
                <span class="pf_bold">{{ item.plstr_cur_down }}</span>
                <span>人下载</span>
              </p> -->
              <el-button
                type="custom_primary"
                size="small"
                @click="
                  $router.push({
                    name: 'STRICT_DETAIL',
                    params: { id: item.plstr_cur_id },
                  })
                "
              >
                进去观看
              </el-button>
            </div>
          </li>
        </ul>
        <div class="no-data--empty" v-if="$isEmpty(listData) && !dataLoad">
          <img src="@assets/images/no-data3.png" alt="" />
          <p>暂无相关课程哦~</p>
        </div>
      </div>
      <!-- 分页控件 -->
      <div class="custom-foot">
        <customPagination
          :current="listPage.pageIndex"
          :total="listPage.total"
          :size="listPage.pageSize"
          @pageChange="flippingPage"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { plastrictcurriculumList } from "@api/home";
import { mapState } from "vuex";
import customPagination from "@comp/customPagination";
export default {
  components: { customPagination },
  computed: { ...mapState("common", ["subjectList", "gradeList"]) },
  data() {
    return {
      listData: [], // 课程列表数据
      /* 表格分页参数 */
      listPage: {
        pageIndex: 1,
        pageSize: 9,
        total: 0,
      },
      searchForm: {
        plstr_cur_title: "",
        plstr_cur_grade: null,
        sysub_id: null,
        plstr_cur_type: 20,
      },
      dataLoad: false,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    /** 获取课程列表 */
    async getData() {
      this.dataLoad = true;
      let params = {
        ...this.searchForm,
        pageindex: this.listPage.pageIndex,
        pagesize: this.listPage.pageSize,
      };
      let { data: res } = await plastrictcurriculumList(params);
      this.dataLoad = false;
      this.listData = res.data;
      this.listPage.total = res.allcount;
    },
    /** 翻页 */
    flippingPage(val) {
      this.listData = [];
      this.listPage.pageIndex = val;
      this.getData();
    },
    /** 筛选数据 */
    searchData() {
      this.listData = [];
      this.listPage.pageIndex = 1;
      this.getData();
    },
  },
};
</script>